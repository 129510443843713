/**
 * File text-rotate.js
 *
 * Handles rotating the text in the header.
 */
document.addEventListener('DOMContentLoaded', initTextAnimSlider);

function initTextAnimSlider() {
  var textAnimHolder = document.querySelector('[data-words]');
  var textAnimItem = document.querySelectorAll('.text-anim-item');
  var textAnimItems = document.querySelector('.text-anim-items');
  var animIn = 'anim-in';
  var animOut = 'anim-out';
  var animNextItem = null;
  var animPrevItem = null;
  var animFirstLoad = false;
  var animDuration = textAnimHolder.getAttribute('data-delay');
  var animCounter = 0;
  var setTimeAnim;
  var setTimeAnimResize;

  animFunc();
  getHolderWidth();

  function animFunc() {
    clearTimeout(setTimeAnim);

    setTimeAnim = setTimeout(function () {
      animFirstLoad = true;

      if (animPrevItem !== null) {
        animPrevItem.classList.add(animOut);
      }
      animNextItem = textAnimItems.children[animCounter];
      animNextItem.classList.remove(animOut);
      animNextItem.classList.add(animIn);

      animPrevItem = animNextItem;

      if (animCounter === textAnimItem.length - 1) {
        animCounter = 0;
      } else {
        animCounter++;
      }
      animFunc();
    }, animFirstLoad ? animDuration : 100);
  }

  function getHolderWidth() {
    var itemsWidth = [];

    for(var i =0; i < textAnimItem.length; i++) {
      itemsWidth.push(textAnimItem[i].clientWidth);
      console.log(textAnimItem[i].clientWidth);
    }

    // var biggestWidth = Math.max.apply(null, itemsWidth) + 'px';

    textAnimHolder.style.width = '260px';
  }

  function resizeHandler() {
    clearTimeout(setTimeAnim);
    clearTimeout(setTimeAnimResize);
    getHolderWidth();

    setTimeAnimResize = setTimeout(function() {
      animFunc();
    }, 50);
  }

  window.addEventListener('resize', resizeHandler);
  window.addEventListener('orientationchange', resizeHandler);
}